import React, { useEffect, useState } from 'react';

import { StripeContext } from 'components/CreditCardForm/types/creditCardForm';
import { usePromo } from 'hooks/usePromo';
import { AppointmentProduct, AppointmentType, ValidPromo } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { ConfirmDetails } from 'screens/Book/components/ConfirmDetails';
import { PromoCode } from 'screens/Book/components/PromoCode/PromoCode';
import { CreditCardDetails } from 'screens/Invoices/components/InvoicePayModal/CreditCardDetails';
import { analytics } from 'utilities/analytics';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';

import { CancellationInfo } from './CancellationInfo';
import { PaymentFormContainer } from './PaymentForm.styled';

interface Props {
  appointment: AppointmentType;
  loading?: boolean;
  noPayment?: () => void;
  onPaymentOptional?: () => void;
  onPaymentTermsChecked: (checked: boolean) => void;
  onSubmit: (stripeTokenId?: string, kbStripeCardStripeIdentifier?: string) => void;
  onValidPromoChange: (validPromo: ValidPromo | null) => void;
  paymentTermsChecked?: boolean;
  product: AppointmentProduct<AppointmentType>;
  hasEmployer?: boolean;
}

export const PaymentForm = (props: Props) => {
  const {
    appointment,
    noPayment,
    onPaymentOptional,
    onPaymentTermsChecked,
    onSubmit,
    onValidPromoChange,
    paymentTermsChecked,
    product,
    loading = false
  } = props;
  const [promoCode, setPromoCode] = useState<string | null>(null);
  const [kbStripeCardStripeIdentifierSelected, setKbStripeCardStripeIdentifier] = useState('');
  const [promoError, setPromoError] = useState(false);
  const [inputValid, setInputValid] = useState(false);
  const [loadingStripeToken, setLoadingStripeToken] = useState(false);
  const { validatePromo } = usePromo(product);
  const [stripeContext, setStripeContext] = useState<StripeContext | undefined>(undefined);
  const [creditCardFieldEmpty, setCreditCardFieldEmpty] = useState(true);
  const isDisabled = () => {
    if (noPayment || onPaymentOptional) {
      return loading;
    }

    return loading || !inputValid || (!stripeContext && !kbStripeCardStripeIdentifierSelected);
  };

  useEffect(() => {
    analytics.track(analytics.EVENTS.PATIENT_PORTAL_BOOKING_APPOINTMENT_PAYMENT_LOADED);
  }, []);

  // clear promo error when promo code is changed
  useEffect(() => {
    if (!promoCode) setPromoError(false);
  }, [promoCode]);

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        if (noPayment) return noPayment();

        // if payment is optional and there is no card info, submit without card info
        // otherwise, go through the normal flow(validate card, get token, submit)
        if (onPaymentOptional && !kbStripeCardStripeIdentifierSelected && creditCardFieldEmpty) {
          return onPaymentOptional();
        }

        if (stripeContext) {
          setLoadingStripeToken(true);
          const tokenResponse = await stripeContext.stripe.createToken(
            stripeContext.cardNumberElement
          );
          setLoadingStripeToken(false);
          if (tokenResponse.error) {
            const tokenResponseError = tokenResponse.error.message;
            if (tokenResponseError) {
              showErrorToast(tokenResponseError);
              BugTracker.notify(tokenResponseError, 'Stripe create token');
            }
            return;
          }
          const stripeTokenId = tokenResponse.token?.id;
          onSubmit(stripeTokenId);
          return;
        }

        if (kbStripeCardStripeIdentifierSelected) {
          onSubmit(undefined, kbStripeCardStripeIdentifierSelected);
        }
      }}
    >
      {!noPayment && (
        <PaymentFormContainer>
          <CreditCardDetails
            paymentOptional={Boolean(onPaymentOptional)}
            onSelectCreditCard={data => setKbStripeCardStripeIdentifier(data)}
            onStripeCardElementInitialized={(stripe, cardNumberElement) => {
              setStripeContext({
                stripe,
                cardNumberElement
              });
            }}
            onValidation={valid => setInputValid(valid)}
            onCreditCardChange={({ empty }) => setCreditCardFieldEmpty(empty)}
          >
            <PromoCode
              value={promoCode || ''}
              error={promoError}
              onChange={value => setPromoCode(value)}
              onSubmit={() => {
                setPromoError(false);
                validatePromo(promoCode)
                  .then(({ validPromo, error }) => {
                    onValidPromoChange(validPromo || null);
                    if (error) {
                      setPromoError(true);
                      analytics.track(analytics.EVENTS.PROMO_CODE_FAILED, {
                        promo_code: promoCode
                      });
                      return showErrorToast(error);
                    }
                    showSuccessToast('Promo code accepted.');
                    analytics.track(analytics.EVENTS.PROMO_CODE_SUCCEEDED, {
                      promo_code: promoCode
                    });
                  })
                  .catch(error => BugTracker.notify(error, 'Validate promo code'));
              }}
            />
          </CreditCardDetails>

          <CancellationInfo />
        </PaymentFormContainer>
      )}

      <ConfirmDetails
        appointment={appointment}
        product={product}
        loading={loading || loadingStripeToken}
        disabled={isDisabled()}
        paymentTermsChecked={paymentTermsChecked}
        onPaymentTermsChecked={onPaymentTermsChecked}
      />
    </form>
  );
};
