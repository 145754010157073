import { ApolloError } from '@apollo/client';
import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import React, { useState } from 'react';

import { XMark } from 'assets/icons/Icons';
import { StripeContext } from 'components/CreditCardForm/types/creditCardForm';
import Modal from 'components/Modal';
import { Button } from 'components/v2/Buttons/Button';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { Heading } from 'components/v2/Typography/Heading';
import { CreditCardDetails } from 'screens/Invoices/components/InvoicePayModal/CreditCardDetails';

import { ModalContent, HeaderContainer, ButtonsContainer, PaymentSection } from '../shared.styled';
import { createStripeTokenId } from './CreditCard.utils';
import { handleError } from './CreditCard.utils';
import { useCreditCardMutation } from './useCreditCardMutation';
import { ERROR_MESSAGE_ADD_CARD } from './useCreditCardMutation.constants';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const NewCreditCardModal = ({ open, onClose }: Props): JSX.Element => {
  const [inputValid, setInputValid] = useState(false);
  const { addCard, addCardLoading } = useCreditCardMutation();

  const [stripeContext, setStripeContext] = useState<StripeContext | undefined>(undefined);

  const onSave = async () => {
    if (!stripeContext) return onClose();

    try {
      const stripeTokenId = await createStripeTokenId(
        stripeContext.stripe,
        stripeContext.cardNumberElement
      );
      await addCard({
        variables: {
          stripeTokenId: stripeTokenId
        }
      });
    } catch (e) {
      handleError(e as ApolloError, ERROR_MESSAGE_ADD_CARD);
    }

    onClose();
  };

  return (
    <Modal label="Add Credit Card Details" open={open} onRequestClose={onClose} mobileFullscreen>
      <ModalContent onSubmit={onSave}>
        <HeaderContainer>
          <Heading tag="h4">Add Credit Card Details</Heading>
          <LinkButton leftIcon={<XMark type="solid" />} size="sm" onClick={onClose} />
        </HeaderContainer>
        <PaymentSection>
          <CreditCardDetails
            onStripeCardElementInitialized={(
              stripe: Stripe,
              cardNumberElement: StripeCardNumberElement
            ) => {
              setStripeContext({
                stripe,
                cardNumberElement
              });
            }}
            onValidation={valid => setInputValid(valid)}
            alwaysShowNewCreditCardForm
          />
        </PaymentSection>
        <ButtonsContainer>
          <Button label={'CANCEL'} category="secondary" size="md" onClick={onClose} />
          <Button
            label={'SAVE'}
            category="primary"
            isDisabled={!inputValid || addCardLoading}
            size="md"
            onClick={onSave}
          />
        </ButtonsContainer>
      </ModalContent>
    </Modal>
  );
};
